import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { BreadcrumbDynamic } from '@org-crowley/enterprise-react-component-library';

export interface LayoutContentsProps {
  children: ReactNode | ReactNode[];
}

export function LayoutContents({ children }: LayoutContentsProps) {
  const router = useRouter();

  return (
    <>
      <BreadcrumbDynamic urlPath={router.pathname} />
      {/* children should be <Component from app.tsx */}
      <main className="h-[calc(100vh-136px)] px-6 py-2 overflow-y-auto">
        {children}
      </main>
    </>
  );
}
